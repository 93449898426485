import './App.css';
import './Calendar.css';
import oilogo from './assets/OILogo.png';
import React from 'react';

function App() {
  return (
    <div className="oi_app">
      
      {/*
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-NR3D5MLQR5"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)};
        gtag('js', new Date());

        gtag('config', 'G-NR3D5MLQR5');
      </script>
      */}

      <header className="oi_header">
        <hgroup className="oi_header_left">
          <span className="oi_diag"/>
          <span className="oi_diag_inside"/>

          <span className="oi_logo">
            <a href="http://outlookinsight.com" target='_blank' rel="noreferrer" tabIndex={-1}>
              <img src={oilogo} alt="Outlook Insight Logo" className="oi_logo_img"/>
            </a>
          </span>

          <h2 className="oi_header_title">Outlook Insight</h2>
          <h4 className="oi_header_subtitle">Modern Technology™</h4>
        </hgroup>
      </header>
      <main className="oi_main">
        <section className="oi_main_body">
          <header className="oi_main_body_header">
            <div className="oi_main_diag">
              <h1 className="oi_main_body_header_text">
              AI Knowledge Interpreter Demos
              </h1>
            </div>
            <div className="oi_main_nav_diag_1"></div>
            <div className="oi_main_nav_diag_2"></div>
            <div className="oi_main_nav_diag_3"></div>
          </header>
        </section>
        <section tabIndex={1} className="oi_main_text">
          <p tabIndex={2} className="oi_main_body_text">
            The <b>Knowledge Interpreter</b> is a groundbreaking artificial intelligence (AI) chatbot tool that helps employees better serve their customers with instant access to the laws, regulations, policies and guidelines of their state agency.
            <br/>
            <br/>
            <ul>
              <li>
                <b>Information is a click away</b>
                <br/>Removes the need to sift through pages of material
              </li>
              <br/>
              <li>
                <b>Plain language answers</b>
                <br/>Quickly read easy-to-understand answers to questions
              </li>
              <br/>
              <li>
                <b>Storytelling approach</b>
                <br/>Relate to answers as they are presented in a format that enhances retention
              </li>
              <br/>
              <li>
                <b>Comprehensive citations</b>
                <br/>Access the authoritative answers to questions instantly
              </li>
            </ul>
            <br/>
            <h4>What Are You Waiting For?</h4>
            <br/>
            Now is the time to speed up the daily workflow, boost team moral, and quickly get your customers the answers they need to make informed decisions about their journey!
          </p>
          <p className="oi_main_body_text_right">
          <a href="https://forms.microsoft.com/r/NjMys4JGxh" tabIndex={-1} target="_blank" rel="noreferrer"><button tabIndex={3} className="oi_contact_button">Contact Us</button></a>
            <br/>
            <br/>
              <h4 className="oi_upcoming_dates_header">Demonstration Dates (PST)</h4>
            <hr/>
            <br/>
              <div>
                <input disabled type="datetime-local" value="2024-09-03T11:00" tabIndex={-1} aria-label="Demo date on September 3, 2024"/>
                <a href="https://events.teams.microsoft.com/event/a3c5ff3e-1efc-4ad0-b3b9-3a300170fbe7@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 3, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-13T10:00" tabIndex={-1} aria-label="Demo date on September 13, 2024"/>
                <a href="https://events.teams.microsoft.com/event/99fda681-8fbf-45dd-b494-feb897c2d305@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 13, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-18T13:00" tabIndex={-1} aria-label="Demo date on September 18, 2024"/>
                <a href="https://events.teams.microsoft.com/event/d3c6f005-eaca-49e4-a789-f203b31917a0@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 18, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-23T11:30" tabIndex={-1} aria-label="Demo date on September 23, 2024"/>
                <a href="https://events.teams.microsoft.com/event/55cf9306-8638-40b8-ac26-eb5a288aacac@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 23, 2024' >Done</a>
              </div>

              <br/>

              <div>
                <input disabled type="datetime-local" value="2024-10-10T11:00" tabIndex={-1} aria-label="Demo date on October 10, 2024"/>
                <a href="https://events.teams.microsoft.com/event/5b022235-0448-4244-9ea4-f1a9c611220d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 10, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-10-14T12:00" tabIndex={-1} aria-label="Demo date on October 14, 2024"/>
                <a href="https://events.teams.microsoft.com/event/12efab5f-3ed4-408c-806f-ca5c165f5da7@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 14, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-10-17T15:00" tabIndex={-1} aria-label="Demo date on October 17, 2024"/>
                <a href="https://events.teams.microsoft.com/event/8b642b29-0d54-4004-b44d-f441146d5ce4@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 17, 2024' >Done</a>
              </div>

              <br/>

              <div>
                <input type="datetime-local" value="2024-11-19T11:00" tabIndex={-1} aria-label="Demo date on November 19, 2024"/>
                <a href="https://events.teams.microsoft.com/event/c6e95d81-393b-4f20-b4bb-5b240165bfe5@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 19, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-22T10:00" tabIndex={-1} aria-label="Demo date on November 22, 2024"/>
                <a href="https://events.teams.microsoft.com/event/a1edd153-56c7-4ba6-b321-ce2a4578a6ff@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 22, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-25T11:00" tabIndex={-1} aria-label="Demo date on November 25, 2024"/>
                <a href="https://events.teams.microsoft.com/event/23e70388-24ea-410d-a356-d44f772c06ef@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 25, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-27T13:00" tabIndex={-1} aria-label="Demo date on November 27, 2024"/>
                <a href="https://events.teams.microsoft.com/event/fdf4d06a-faa4-417f-bc13-143038361a8d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 27, 2024' >Register</a>
              </div>

            {/*<iframe width="100%" height="100%" src="https://www.youtube.com/embed/jNQXAC9IVRw?si=NQb97wEO0SQAqRmh" title="Knowledge Interpreter Video Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>*/}
          </p>
        </section>
        <section className="oi_main_content">
          <div className="oi_main_content_left">
            <div className="calendar">
            <div className="oi_calendar_header">
                <center>
                  <h1>Demos</h1>
                  <h3>November 2024</h3>
                </center>
              </div>
              <ul className="weekdays">
                <li>
                  <abbr title="S">Sunday</abbr>
                </li>
                <li>
                  <abbr title="M">Monday</abbr>
                </li>
                <li>
                  <abbr title="T">Tuesday</abbr>
                </li>
                <li>
                  <abbr title="W">Wednesday</abbr>
                </li>
                <li>
                  <abbr title="T">Thursday</abbr>
                </li>
                <li>
                  <abbr title="F">Friday</abbr>
                </li>
                <li>
                  <abbr title="S">Saturday</abbr>
                </li>
              </ul>

              <ol className="day-grid">
                <li className="inactive">27</li>
                <li className="inactive">28</li>
                <li className="inactive">29</li>
                <li className="inactive">30</li>
                <li className="inactive">31</li>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
                <li>14</li>
                <li>15</li>
                <li>16</li>
                <li>17</li>
                <li>18</li>
                <li className="text_center">
                <a href="https://events.teams.microsoft.com/event/c6e95d81-393b-4f20-b4bb-5b240165bfe5@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="cal_register" target="_blank" rel="noreferrer">
                    19
                  <div className="active_hidden">
                      <br/>
                      <b>Register</b>
                      <br/>
                      <div className="cal_time">
                      11:00 AM PST
                      </div>
                    </div>
                  </a>
                </li>
                <li>20</li>
                <li>21</li>
                <li className="text_center">
                <a href="https://events.teams.microsoft.com/event/a1edd153-56c7-4ba6-b321-ce2a4578a6ff@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="cal_register" target="_blank" rel="noreferrer">
                    22
                  <div className="active_hidden">
                      <br/>
                      <b>Register</b>
                      <br/>
                      <div className="cal_time">
                      10:00 AM PST
                      </div>
                    </div>
                  </a>
                </li>
                <li>23</li>
                <li>24</li>
                <li className="text_center">
                <a href="https://events.teams.microsoft.com/event/23e70388-24ea-410d-a356-d44f772c06ef@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="cal_register" target="_blank" rel="noreferrer">
                    25
                  <div className="active_hidden">
                      <br/>
                      <b>Register</b>
                      <br/>
                      <div className="cal_time">
                      11:00 AM PST
                      </div>
                    </div>
                  </a>
                </li>
                <li>26</li>
                <li className="text_center">
                <a href="https://events.teams.microsoft.com/event/fdf4d06a-faa4-417f-bc13-143038361a8d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="cal_register" target="_blank" rel="noreferrer">
                    27
                  <div className="active_hidden">
                      <br/>
                      <b>Register</b>
                      <br/>
                      <div className="cal_time">
                      1:00 PM PST
                      </div>
                    </div>
                  </a>
                </li>
                <li>28</li>
                <li>29</li>
                <li>30</li>
              </ol>

              {/* <li className="text_center">
                <a href="https://events.teams.microsoft.com/event/5b022235-0448-4244-9ea4-f1a9c611220d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="cal_register done" target="_blank" rel="noreferrer">
                    10
                  <div className="active_hidden">
                      <br/>
                      <b>Done</b>
                      <br/>
                      <div className="cal_time">
                      11:00 AM PST
                      </div>
                    </div>
                  </a>
                </li> */}
            </div>
          </div>
          <div className="oi_main_content_right">
            <button className="oi_contact_button"><a href="https://forms.microsoft.com/r/NjMys4JGxh" target="_blank" rel="noreferrer">Contact Us</a></button>
            <br/>
            <br/>
              <h4 className="oi_upcoming_dates_header">Upcoming Demonstration Dates</h4>
              <hr/>
              <div>
                <input disabled type="datetime-local" value="2024-09-03T11:00" tabIndex={-1} aria-label="Demo date on September 3, 2024"/>
                <a href="https://events.teams.microsoft.com/event/a3c5ff3e-1efc-4ad0-b3b9-3a300170fbe7@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 3, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-13T10:00" tabIndex={-1} aria-label="Demo date on September 13, 2024"/>
                <a href="https://events.teams.microsoft.com/event/99fda681-8fbf-45dd-b494-feb897c2d305@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 13, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-18T13:00" tabIndex={-1} aria-label="Demo date on September 18, 2024"/>
                <a href="https://events.teams.microsoft.com/event/d3c6f005-eaca-49e4-a789-f203b31917a0@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 18, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-09-23T11:30" tabIndex={-1} aria-label="Demo date on September 23, 2024"/>
                <a href="https://events.teams.microsoft.com/event/55cf9306-8638-40b8-ac26-eb5a288aacac@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on September 23, 2024' >Done</a>
              </div>

              <br/>

              <div>
                <input disabled type="datetime-local" value="2024-10-10T11:00" tabIndex={-1} aria-label="Demo date on October 10, 2024"/>
                <a href="https://events.teams.microsoft.com/event/5b022235-0448-4244-9ea4-f1a9c611220d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 10, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-10-14T12:00" tabIndex={-1} aria-label="Demo date on October 14, 2024"/>
                <a href="https://events.teams.microsoft.com/event/12efab5f-3ed4-408c-806f-ca5c165f5da7@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 14, 2024' >Done</a>
              </div>
              <div>
                <input disabled type="datetime-local" value="2024-10-17T15:00" tabIndex={-1} aria-label="Demo date on October 17, 2024"/>
                <a href="https://events.teams.microsoft.com/event/8b642b29-0d54-4004-b44d-f441146d5ce4@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register date_done" target="_blank" rel="noreferrer" aria-label='Demo date on October 17, 2024' >Done</a>
              </div>
              
              <br/>

              <div>
                <input type="datetime-local" value="2024-11-19T11:00" tabIndex={-1} aria-label="Demo date on November 19, 2024"/>
                <a href="https://events.teams.microsoft.com/event/c6e95d81-393b-4f20-b4bb-5b240165bfe5@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 19, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-22T10:00" tabIndex={-1} aria-label="Demo date on November 22, 2024"/>
                <a href="https://events.teams.microsoft.com/event/a1edd153-56c7-4ba6-b321-ce2a4578a6ff@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 22, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-25T11:00" tabIndex={-1} aria-label="Demo date on November 25, 2024"/>
                <a href="https://events.teams.microsoft.com/event/23e70388-24ea-410d-a356-d44f772c06ef@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 25, 2024' >Register</a>
              </div>
              <div>
                <input type="datetime-local" value="2024-11-27T13:00" tabIndex={-1} aria-label="Demo date on November 27, 2024"/>
                <a href="https://events.teams.microsoft.com/event/fdf4d06a-faa4-417f-bc13-143038361a8d@b39ef6b0-f4c4-4266-b067-f0ae2067d705" className="oi_register" target="_blank" rel="noreferrer" aria-label='Demo date on November 27, 2024' >Register</a>
              </div>

            <hr/>
          </div>
        </section>
      </main>
      <footer className="oi_footer">
        <p className="oi_footer_text">©2024 Outlook Insight</p>
      </footer>
    </div>
  );
}

export default App;
